.contacts-block {
  &__item {
    @include flex(flex-start, center, row, wrap);
    @include notlast {
      margin-bottom: 15px; }
    a {
      @include tr(color .3s);
      @include hover {
        color: $accent; } }
    .social {
      @include notfirst {
        margin-left: 10px; } } } }
