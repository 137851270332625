@include font('robotocondensed', 'robotocondensed-light-webfont', 300);
@include font('robotocondensed', 'robotocondensed-regular-webfont', 400);
// +font('robotocondensed', 'robotocondensed-bold-webfont', 700)

@include font('robotocondensed', 'robotocondensed-lightitalic-webfont', 300, italic);

@include font('cour', 'cour-webfont', 400);

@mixin cour {
  font-family: courier, serif;
  font-weight: 400; }
