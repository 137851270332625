small {
  font-size: inherit; }

h1, h2, h3, h4, h5, h6 {
  font-weight: 300; }

strong {
  font-weight: 400; }

.title {
  color: $black;
  text-transform: uppercase;
  display: block;
  line-height: 1;
  &--h2 {
    font-size: em(24, 14); }
  &--h3 {
    // +css-lock(16, 18, 320, 1399, font-size)
    font-size: em(18, 14); }
  &--h4 {
    font-size: em(16, 14);
 }    // +css-lock(13, 16, 767, 1399, font-size)
  &--ttn {
    text-transform: none; }
  &--tdu {
    text-decoration: underline;
    @include hover {
      text-decoration: none; } }
  &--inline {
    display: inline; }
  &--reg {
    font-weight: 400; } }
