.article {
  text-align: justify;
  p, ul, ol, h1, h2, h3, h4, h5, h6, img, li, figure {
    @include notlast {
      margin-bottom: 25px;
      @include md {
        margin-bottom: 15px; } } }
  h3 {
    @extend .title;
    @extend .title--h3;
    @extend .title--ttn;
    color: inherit; }
  a {
    color: $accent;
    text-decoration: underline; }
  ul {
    li {
      &::before {
        content: "\2022";
        display: inline-block;
        margin-right: 2em; } } }
  figure {
    img {
      width: 100%; } }

  &--col-3 {
    column-gap: 30px;
    column-count: 3;
    @include md {
      column-gap: 20px; }
    @include sm {
      column-gap: 0px;
      column-count: initial;
      // max-height: 500px
 } } }      // overflow: auto
