.section {
  padding-top: 40px;
  padding-bottom: 40px;
  @include lg {
    padding-top: 30px;
    padding-bottom: 30px; }
  @include md {
    padding-bottom: 15px;
    padding-top: 15px; }
  &__title {
    text-align: center;
    margin-bottom: 40px;
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  &__title-left {
    text-align: left; }
  &__text {
    margin-bottom: 40px;
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } }
  &--page {
    padding-top: 30px;
    padding-bottom: 100px;
    @include lg {
      // padding-top: 60px
      padding-bottom: 60px; }
    @include md {
      // padding-top: 50px
      padding-bottom: 50px; }
    @include sm {
      // padding-top: 30px
      padding-bottom: 30px; } } }

.section-block {
  // min-height: 400px
  @include flex;
  @include xs {
    display: block; }
  &__left, &__right {
    @include flex(center, normal, column);
    @include xs {
      display: block; } }
  &__left {
    padding-right: 15px;
    @include md {
      padding-right: 5px; }
    @include xs {
      padding-right: 0;
      margin-bottom: 15px; } }
  &__right {
    padding-left: 15px;
    @include md {
      padding-left: 5px; }
    @include xs {
      padding-left: 0; } } }
