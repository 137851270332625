.form {
  &__row {
    margin-left: -5px;
    margin-right: -5px;
    @include flex(flex-start, normal, row, wrap); }
  &__col {
    padding-left: 5px;
    padding-right: 5px;
    width: 25%;
    margin-bottom: 10px;
    @include lg {
      width: 50%; }
    @include xs {
      width: 100%; } }
  &__field {
    margin-bottom: 10px; } }

.input, .textarea {
  color: $black;
  input, textarea {
    border: 1px solid  $gray-light;
    height: 40px;
    padding: .5em;
    width: 100%;
    background-color: transparent;
    font-size: em(18, 14);
    @include placeholder {
      color: inherit; } }
  textarea {
    height: auto; } }

.attach {
  position: relative;
  input {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0; } }
