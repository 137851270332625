.slider {
  &__wrap {
    position: relative; }
  &__prev, &__next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
    &[disabled] {
      display: none; } }
  &__prev {
    left: -50px; }
  &__next {
    right: -50px; }
  &--items {
    &__wrap {
      @include lg {
        padding-left: 30px;
        padding-right: 30px; }
      @include md;

      .slider {
        &__prev {
          @include lg {
            left: -10px; } }
        &__next {
          @include lg {
            right: -10px; } } } } } }


.prev, .next {
  @include inline-flex(center, center);
  color: $white;
  @include tr(color .3s);
  background-color: $black;
  width: 30px;
  height: 30px; }
