.breadcrumbs {
  text-transform: uppercase;
  font-size: 11px;
  @include flex(flex-start, center);
  ul {
 }    // margin-left: .5em
  li {
    display: inline-block; }
  a {
    text-decoration: underline;
    @include hover {
      text-decoration: none; }
    &.is-active {
      text-decoration: none;
      pointer-events: none; } } }
