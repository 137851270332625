.member {
  $this: &;
  display: block;
  @include xs {
    text-align: center; }
  &__img {
    @include cover;
    padding-top: 135%;
    margin-bottom: 25px;
    filter: grayscale(100%);
    @include md {
      margin-bottom: 15px; }
    @include xs {
      padding-top: 60%;
      background-size: 40%;
 }      // background-position: 0 50%
    @include xss {
      padding-top: 80%;
      background-size: 50%; } }
  &__title {
    display: block; }
  &__subttl {
    margin-bottom: 20px;
    @include md {
      margin-bottom: 10px; } }

  &--hor {
    @include flex(flex-start, normal, row, wrap);
    @include xs {
      display: block; }
    #{$this}__img {
      width: calc(#{percentage(2/6)} - 30px);
      margin-right: 30px;
      padding-top: 40%;
      margin-bottom: 0;
      // margin-right: 30px
      @include md {
        margin-right: 10px;
        width: calc(#{percentage(2/6)} - 10px); }
      @include xs {
        width: auto;
        margin-bottom: 15px;
        padding-top: 60%; }
      @include xss {
        padding-top: 80%; } }
    #{$this}__content {
      @include flex(center, normal, column);
      width: calc(#{percentage(4/6)});
      @include xs {
        width: auto; } } }

  &--sm {
    #{$this}__img {
      max-width: 165px;
      padding-top: 110%;
      @include xs {
        max-width: none;
        padding-top: 60%; } } } }
