.grid {
  @include flex(flex-start, normal, row, wrap);
  margin-left: -15px;
  margin-right: -15px;
  @include md {
    margin-left: -5px;
    margin-right: -5px; }
  // +sm
  //   margin-left: -5px
  //   margin-right: -5px
  &__col {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 40px;
    @include md {
      padding-left: 5px;
      padding-right: 5px;
      margin-bottom: 20px; }
    // +sm
    //   padding-right: 5px
    //   padding-left: 5px
    @include xs {
      width: 100%; } }
  &__col-2-6 {
    width: percentage(2/6); }
  &__col-4-6 {
    width: percentage(4/6); }
  &__col-3-6 {
    width: percentage(3/6); } }
