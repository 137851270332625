.news-item {
  display: block;
  &__content {
    display: block; }
  &__date {
    padding-top: .2em;
    text-transform: uppercase; }
  &__img {
    @include cover;
    display: block;
    margin-bottom: 20px;
    padding-top: 60%;
    @include md {
      margin-bottom: 10px; } }
  &__title {
    margin-bottom: 20px;
    display: block;
    @include md {
      margin-bottom: 10px; } }
  &__text {
    margin-bottom: 20px;
    display: block;
    text-align: justify;
    @include md {
      margin-bottom: 10px; } }
  &__link {
    display: block; } }

.news-links {
  li {
    @include notlast {
      margin-bottom: 20px;
      @include md {
        margin-bottom: 10px; } } }
  a {
    @extend .title;
    @extend .title--h3;
    @extend .title--ttn;
    @extend .title--tdu;
    @extend .title--inline;
    @include tr(color .3s);
    @include hover {
      color: $accent; } } }
