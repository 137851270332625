.hero {
  &__burger {
    display: none;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    @include sm {
      display: block; } } }

.hero-grid {
  @include clr;
  width: 100%; }

.hero-grid-item {
  $htis: &;
  display: block;
  float: left;
  position: relative;
  padding-top: 18%;
  width: 33%;
  background-color: $black;
  @include cover;
  @include hover {
    #{$htis}__cover {
      visibility: visible;
      opacity: 1; } }
  img {
    @include vcenter;
    max-height: 90%; }
  &:nth-child(1) {
    width: percentage(330/1080); }
  &:nth-child(2) {
    width: percentage(420/1080); }
  &:nth-child(3) {
    width: percentage(330/1080); }
  &:nth-child(4) {
    width: percentage(330/1080);
    padding-top: 30%; }
  &:nth-child(5) {
    width: percentage(420/1080); }
  &:nth-child(6) {
    width: percentage(330/1080); }
  &:nth-child(7) {
    width: percentage(420/1080);
    padding-top: 12%; }
  &:nth-child(8) {
    width: percentage(330/1080);
    padding-top: 12%; }
  &__cover {
    @include flex(center, center);
    color: $white;
    @include coverdiv;
    background-color: rgba($accent, .6);
    @include css-lock(13, 18, 767, 1399, font-size);
    text-transform: uppercase;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    @include ready {
      @include tr(visibility .3s, opacity .3s); } } }

.burger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 20px;
  position: relative;
  color: $black;
  span:after, span:before {
    content: "";
    position: absolute;
    left: 0; }
  span:after {
    top: 0px; }
  span:before {
    bottom: 0px; }
  span {
    display: block; }
  span, span:after, span:before {
    width: 100%;
    height: 3px;
    background-color: currentColor;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px; } }

