body {
  background: $white;
  line-height: 1.4;
  font-size: $fz;
  min-width: 320px;
  font-family: 'robotocondensed', sans-serif;
  font-weight: 300;
  color: $c-text;
  @include no-scroll;
  // +sm
 }  //// font-size: $fz-sm-px

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  &.is-loaded:not(.no-animation) {
    animation: fadeIn 1s; } }



.out {
  $this: &;
  &--page {
    html:not(.is-ie) & {
      min-height: 100vh;
      @include flex(flex-start, normal, column); }
    #{$this}__content {
      @extend .container;
      @extend .container--sm;
      position: relative;
      // +flex
      @include clr;
      html:not(.is-ie) & {
        flex-grow: 1;
        width: 100%; } } } }

.main {
  &--page {
    float: left;
    width: calc(100% - #{$aside-width});
    @include sm {
      width: auto;
      float: none; } } }
.container {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  &--sm {
    max-width: 1080px; }
  &--md {
    max-width: 1110px; } }

.hr, hr {
  display: block;
  width: 100%;
  border-color: $gray-light;
  border-top: 1px solid;
  color: transparent;
  outline: none;
  height: 0;
  margin: 0;
  margin-top: 40px;
  @include lg {
    margin-top: 30px; }
  @include md {
    margin-top: 15px; }
  &--mb {
    margin-bottom: 40px;
    @include lg {
      margin-bottom: 30px; }
    @include md {
      margin-bottom: 15px; } } }

.link {
  @include cour;
  text-transform: uppercase;
  border-bottom: 1px solid currentColor;
  @include tr(color .3s);
  @include hover {
    color: $accent;
    border-bottom: 0; } }

.list {
  text-align: justify;
  li {
    @include notlast {
      margin-bottom: 10px; }
    &::before {
      content: '-';
      display: inline-block;
      margin-right: .5em; } } }

.btn {
  color: $black;
  display: inline-block;
  border: 1px solid currentColor;
  padding: 0.4em;
  height: 40px;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  font-size: em(18, 14);
  @include tr(color .3s);
  @include hover {
    text-decoration: none;
    color: $accent; }
  &--full {
    width: 100%; } }

.social {
  li {
    display: inline-block; }
  a {
    @include flex(center, center);
    border: 1px solid currentColor;
    width: 25px;
    height: 25px;
    @include tr(color .3s);
    @include hover {
      color: $accent; }
    .icon {
      font-size: 1.1em; } } }
