.aside {
  position: sticky;
  z-index: 10;
  top: 0;
  float: left;
  overflow: auto;
  overflow: auto;
  width: $aside-width;
  padding-left: 15px;
  padding-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: $white;
  @include ready {
    @include tr(transform .3s, visibility .3s, opacity .3s); }
  @include sm {
    // transform: translate(calc(-100% - 15px), 0)
    visibility: hidden;
    opacity: 0;
    float: none;
    position: fixed;
    left: 0;
    width: 100%;
    bottom: 0;
    padding: 15px;
    padding-top: 40px;
    &.is-active {
      // transform: translate(0, 0)
      visibility: visible;
      opacity: 1; } }
  &__logo {
    margin-bottom: 60px;
    @include sm {
      display: none; } }
  &__close {
    @extend .header__close; }
  &__contacts-mob {
    @extend .header__contacts-mob; }
  &__nav {
    @include sm {
      border-bottom: 1px solid  $gray-light;
      margin-bottom: 30px;
      padding-bottom: 20px; } } }
