.nav {
  li {
    // @extend .title
    // @extend .title--h4
    text-transform: uppercase;
    font-size: 16px;
    display: inline-block;
    position: relative;
    @include notlast {
      margin-right: 20px; }
    @include hover {
      > ul {
        visibility: visible;
        opacity: 1; } }
    @include sm {
      display: block;
      padding-top: 10px;
      padding-bottom: 10px;
      @include notlast {
        margin-right: 0; } }
    > ul {
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 0;
      background-color: $white;
      width: 300px;
      visibility: hidden;
      padding-top: 20px;
      padding-bottom: 10px;
      opacity: 0;
      @include ready {
        @include tr(opacity .3s, visibility .3s); }
      @include sm {
        visibility: inherit;
        opacity: 1;
        position: static;
        padding-top: 10px;
        padding-bottom: 0;
        width: auto; }
      li {
        display: block;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 10px;
        padding-right: 10px;
        @include notlast {
          margin-right: 0; }
        @include before {
          content: '-';
          display: inline-block;
          margin-right: .5em; }
        @include sm {
          padding-top: 10px;
          padding-bottom: 10px;
          padding-left: 0;
          &:last-child {
            padding-bottom: 0; } } } } }

  a {
    @include tr(color .3s);
    @include hover {
      color: $accent; }
    &.is-active {
      color: $accent; } }

  &--sm {
    li {
      font-size: $fz;
      @include sm {
        padding-top: 5px;
        padding-bottom: 5px; } }
    a {
      text-decoration: underline; } }

  &--aside {
    li {
      text-transform: none;
      display: block;
      padding-top: 5px;
      padding-bottom: 5px;
      @include notlast {
        margin-right: 0; }
      > ul {
        visibility: inherit;
        position: static;
        opacity: 1;
        padding-top: 0;
        padding-bottom: 0;
        width: auto;
        li {
          padding-left: 0; } } } } }
