$fz:       14px;
$fz-sm-px: 13px;
$fz-xs-px: 12px;
$fz-sm:    em(13);
$fz-xs:    em(12);

$c-text: #444444;

$black: #000;
$white: #fff;
$gray: #959595;
$gray-light: #d7d7d7;
$accent: #2c0642;

$aside-width: 300px;
